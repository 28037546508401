
































import {Component, Prop, Vue} from "vue-property-decorator";
import {Station} from "@/models/station";

@Component({})
export default class StationHeaderComponent extends Vue {

  @Prop({ default: () => new Station({}) })
  public station!: Station;
}

